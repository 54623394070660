<template>
  <div>
    <div class="p-1">
      <b-row>
        <b-col
            v-if="$can('create', 'smstemplate')"
            class="col d-flex justify-content-end"
        >
          <b-button variant="primary" @click="addNewTemplate">
            <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-card no-body class="mb-0">
      <b-table
          ref="refTemplateSmsListTable"
          class="position-relative table-white-space max-height-table"
          sticky-header
          head-variant="light"
          :no-border-collapse="true"
          :items="fetchTemplateSmsList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >
        <template #cell(text_msg)="data">
          <div style="max-width: 400px; white-space: pre-wrap">
            {{ data.item.content }}
          </div>
        </template>

        <template #cell(created_by)="data">
          <b-link>
            {{ data.item.created_by }}
          </b-link>
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'smstemplate')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="updateNewTemplate(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalAdjustmentReason"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add adjustmentReason Modal -->
    <AddNewTemplateSmsModal @refetch-data="refetchData" />

    <!-- Update adjustmentReason Modal -->
    <UpdateTemplateSmsModal 
      @refetch-data="refetchData" 
      :templateSmsDetail.sync="templateSmsDetail"
      />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  VBTooltip
} from "bootstrap-vue";
import { onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  resolveCurrency,
  formatDateTime,
} from "@core/utils/filter";
import store from "@/store";
import templateSmsStoreModule from "@/views/reason/template-sms/templateSmsStoreModule";
import useTemplateSms from "@/views/reason/template-sms/useTemplateSms";
import AddNewTemplateSmsModal from "@/views/reason/template-sms/AddNewTemplateSmsModal";
import UpdateTemplateSmsModal from "@/views/reason/template-sms/UpdateTemplateSmsModal";

export default {
  components: {
    AddNewTemplateSmsModal,
    UpdateTemplateSmsModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      templateSmsDetail: null,
      // type: null,
    };
  },
  methods: {
    addNewTemplate () {
      this.$bvModal.show('modal-add-new-template')
    },
    updateNewTemplate (data) {
      this.templateSmsDetail = data;
      this.$bvModal.show('modal-update-template')
    },
  },
  setup() {
    if (!store.hasModule("template-sms"))
      store.registerModule("template-sms", templateSmsStoreModule);
    onUnmounted(() => {
      if (store.hasModule("template-sms")) store.unregisterModule("template-sms");
    });

    const {
      fetchTemplateSmsList,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustmentReason,
      dataMeta,
      refTemplateSmsListTable,
      refetchData,
      idFilter,
    } = useTemplateSms();


    return {
      fetchTemplateSmsList,
      tableColumns,
      perPage,
      currentPage,
      totalAdjustmentReason,
      dataMeta,
      refTemplateSmsListTable,
      refetchData,
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      idFilter,
    };
  },
};
</script>
<style scoped>
.max-height-table {
  max-height: 580px;
}
</style>